<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Cartas porte </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Cartas porte" icon="truck-outline">
        <PorteCardsList></PorteCardsList>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import PorteCardsList from "../components/PorteCardsList";

// @ is an alias to /src
export default {
  name: "PorteCardsComponents",
  components: {
    PorteCardsList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "porteCards");
  },
  async created() {
    let allPromises = [];

    if (this.$store.getters.PACKAGINGS.length === 0) {
      allPromises.push(this.$store.dispatch("GETPACKAGINGS"));
    }
    if (this.$store.getters.TRANSPORTATIONPERMISSIONS.length === 0) {
      allPromises.push(this.$store.dispatch("GETTRASNPORTATIONPERMISSIONS"));
    }
    allPromises.push(this.$store.dispatch("GETVEHICULES"));
    if (this.$store.getters.TRANSPORTATIONCONFIGURATION.length === 0) {
      allPromises.push(this.$store.dispatch("GETTRANSPORTATIONCONFIGURATION"));
    }
    if (this.$store.getters.TRAILERTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETTRAILERTYPE"));
    }
    if (this.$store.getters.WEIGHTUNITS.length === 0) {
      allPromises.push(this.$store.dispatch("GETWEIGHTUNITS"));
    }
    if (this.$store.getters.DANGEROUSMATERIALS.length === 0) {
      allPromises.push(this.$store.dispatch("GETDANGEROUSMATERIALS"));
    }
    allPromises.push(this.$store.dispatch("GETARTICLES"));

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
