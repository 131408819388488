/* eslint-disable */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar carta porte</h1>

    <div class="control add-global-controls" v-if="!objectInformation">
      <b-button
        type="is-primary"
        class="add-articles-special-margin"
        @click="addDriver()"
        >Agregar chofer</b-button
      >
    </div>

    <div>
      <h3 class="form-division">Información general</h3>

      <div class="invoice-add-item special-margin">
        <b-field
          class="invoice-add-secondary-item-one"
          label-position="inside"
          :label="`Total de distancia recorrida (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.DISTANCIA_TOTAL }"
          :message="{
            'La distancia recorrida total no es válida':
              newPorteCardErrors.DISTANCIA_TOTAL,
          }"
        >
          <b-input
            :placeholder="`100`"
            icon="map-marker-distance"
            required
            v-model="newPorteCard.DISTANCIA_TOTAL"
            type="number"
            step="any"
          >
          </b-input>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-one"
          label-position="inside"
          :label="`RFC del destinatario(Req)`"
          :type="{ 'is-danger': newPorteCardErrors.RFC }"
          :message="{
            'El RFC del destinatario no es válido': newPorteCardErrors.RFC,
          }"
        >
          <b-input
            :placeholder="`XAXX010101000`"
            icon="account"
            required
            v-model="newPorteCard.RFC"
          >
          </b-input>
        </b-field>
      </div>

      <h3 class="form-division">Información del origen</h3>

      <div class="invoice-add-item special-margin">
        <b-field
          class="invoice-add-secondary-item-one"
          label-position="inside"
          label="Fecha de salida (Req)"
        >
          <b-datepicker
            :show-week-number="true"
            v-model="newPorteCard.FECHA_SALIDA"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            placeholder="Fecha"
            icon="calendar-today"
            required
            trap-focus
          >
          </b-datepicker>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Id del origen (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.ID_UBICACION }"
          :message="{
            'El id del origen no es válido': newPorteCardErrors.ID_UBICACION,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: OR123456`"
            icon="map-marker-outline"
            required
            v-model="newPorteCard.ID_UBICACION"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Dirección (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.DIRECCION }"
          :message="{
            'La dirección no es válida': newPorteCardErrors.DIRECCION,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: Paseo de la rosa`"
            icon="file-outline"
            required
            v-model="newPorteCard.DIRECCION"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Número exterior (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.NUMERO_EXTERIOR }"
          :message="{
            'El número exterior no es válido':
              newPorteCardErrors.NUMERO_EXTERIOR,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: 200`"
            icon="counter"
            required
            v-model="newPorteCard.NUMERO_EXTERIOR"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Número interior (Opc)`"
        >
          <b-input
            :placeholder="`Ejemplo: 21`"
            icon="counter"
            v-model="newPorteCard.NUMERO_INTERIOR"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Referencia (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.REFERENCIA }"
          :message="{
            'La referencia no es válida': newPorteCardErrors.REFERENCIA,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: Casa blanca`"
            icon="file-outline"
            required
            v-model="newPorteCard.REFERENCIA"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Código postal (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.CP }"
          :message="{
            'El código postal no es válido': newPorteCardErrors.CP,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: 00000`"
            icon="directions-fork"
            required
            v-model="newPorteCard.CP"
            v-cleave="masks.customCP"
            @input="(value) => searchForOriginDirectionInformation(value, 0)"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Nombre de la colonia (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.NOMBRE_COLONIA }"
          :message="{
            'El nombre de la colonia no es válido':
              newPorteCardErrors.NOMBRE_COLONIA,
          }"
        >
          <b-autocomplete
            :data="
              getFilteredColonies(
                this.newPorteCard.zipCodes,
                this.newPorteCard.NOMBRE_COLONIA
              )
            "
            field="colony"
            open-on-focus
            placeholder="Ejemplo: Del Norte"
            expanded
            icon="home-group"
            v-model="newPorteCard.NOMBRE_COLONIA"
            clearable
            @select="(option) => (newPorteCard.NOMBRE_COLONIA = option.colony)"
          >
            <template #empty>No se encontraron colonias</template>
          </b-autocomplete>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Clave de la colonia (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.COLONIA }"
          :message="{
            'La clave de la colonia no es válida': newPorteCardErrors.COLONIA,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: 0001`"
            icon="sign-real-estate"
            required
            v-model="newPorteCard.COLONIA"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Clave de la localidad (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.LOCALIDAD }"
          :message="{
            'La localidad no es válido': newPorteCardErrors.LOCALIDAD,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: 01`"
            icon="sign-real-estate"
            required
            v-model="newPorteCard.LOCALIDAD"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Nombre del municipio (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.NOMBRE_MUNICIPIO }"
          :message="{
            'El nombre de múnicipio exterior no es válido':
              newPorteCardErrors.NOMBRE_MUNICIPIO,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: Aguascalientes`"
            icon="sign-real-estate"
            required
            v-model="newPorteCard.NOMBRE_MUNICIPIO"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Clave del municipio (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.MUNICIPIO }"
          :message="{
            'La clave de municipio no es válida': newPorteCardErrors.MUNICIPIO,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: 001`"
            icon="city-variant-outline"
            required
            v-model="newPorteCard.MUNICIPIO"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three required-input"
          label-position="inside"
          :label="`Estado (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.ESTADO }"
          :message="{
            'El estado no es válido': newPorteCardErrors.ESTADO,
          }"
        >
          <b-select
            :placeholder="`Ejemplo: Aguascalientes`"
            icon="sign-real-estate"
            required
            v-model="newPorteCard.ESTADO"
            expanded
          >
            <option value="" disabled>Seleccione un estado</option>
            <option
              v-for="singleState in states"
              :value="singleState.CLAVE"
              :key="singleState.NOMBRE"
            >
              {{ singleState.NOMBRE }}
            </option>
          </b-select>
        </b-field>
      </div>

      <h3 class="form-division">Información del destino</h3>

      <div
        class="invoice-add-item special-margin"
        v-for="(singleDestino, index) in newPorteCard.DESTINOS"
        v-bind:key="index"
      >
        <b-field
          class="invoice-add-secondary-item-one"
          label-position="inside"
          label="Fecha de llegada (Req)"
        >
          <b-datepicker
            :show-week-number="true"
            v-model="singleDestino.FECHA_SALIDA"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            placeholder="Fecha"
            icon="calendar-today"
            required
            trap-focus
          >
          </b-datepicker>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-one"
          label-position="inside"
          :label="`Distancia (Req)`"
        >
          <b-input
            :placeholder="`100`"
            icon="map-marker-distance"
            required
            v-model="singleDestino.DISTANCIA"
            type="number"
            step="any"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Id del destino (Req)`"
        >
          <b-input
            :placeholder="`Ejemplo: DE123456`"
            icon="map-marker-outline"
            required
            v-model="singleDestino.ID_UBICACION"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Dirección (Req)`"
        >
          <b-input
            :placeholder="`Ejemplo: paseo de la flor`"
            icon="file-outline"
            required
            v-model="singleDestino.DIRECCION"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Referencia (Req)`"
        >
          <b-input
            :placeholder="`Ejemplo: Casa blanca`"
            icon="file-outline"
            required
            v-model="singleDestino.REFERENCIA"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Número exterior (Req)`"
        >
          <b-input
            :placeholder="`Ejemplo: 200`"
            icon="counter"
            required
            v-model="singleDestino.NUMERO_EXTERIOR"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Número interior (Req)`"
        >
          <b-input
            :placeholder="`Ejemplo: 21`"
            icon="counter"
            required
            v-model="singleDestino.NUMERO_INTERIOR"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Código postal (Req)`"
        >
          <b-input
            :placeholder="`Ejemplo: 00000`"
            icon="directions-fork"
            required
            v-model="singleDestino.CP"
            v-cleave="masks.customCP"
            @input="
              (value) => searchForOriginDirectionInformation(value, 1, index)
            "
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Nombre de la colonia (Req)`"
        >
          <b-autocomplete
            :data="
              getFilteredColonies(
                singleDestino.zipCodes,
                singleDestino.NOMBRE_COLONIA
              )
            "
            field="colony"
            open-on-focus
            placeholder="Ejemplo: Del Norte"
            expanded
            icon="home-group"
            v-model="singleDestino.NOMBRE_COLONIA"
            clearable
            @select="(option) => (singleDestino.NOMBRE_COLONIA = option.colony)"
          >
            <template #empty>No se encontraron colonias</template>
          </b-autocomplete>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Clave de la colonia (Req)`"
        >
          <b-input
            :placeholder="`Ejemplo: 0001`"
            icon="sign-real-estate"
            required
            v-model="singleDestino.COLONIA"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Clave de la localidad (Req)`"
        >
          <b-input
            :placeholder="`Ejemplo: 01`"
            icon="sign-real-estate"
            required
            v-model="singleDestino.LOCALIDAD"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Nombre del municipio (Req)`"
        >
          <b-input
            :placeholder="`Ejemplo: Aguascalientes`"
            icon="sign-real-estate"
            required
            v-model="singleDestino.NOMBRE_MUNICIPIO"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Clave del municipio (Req)`"
        >
          <b-input
            :placeholder="`Ejemplo: 001`"
            icon="city-variant-outline"
            required
            v-model="singleDestino.MUNICIPIO"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three required-input"
          label-position="inside"
          :label="`Estado (Req)`"
        >
          <b-select
            :placeholder="`Ejemplo: Aguascalientes`"
            icon="sign-real-estate"
            required
            v-model="singleDestino.ESTADO"
            expanded
          >
            <option value="">Seleccione un estado</option>
            <option
              v-for="singleState in states"
              :value="singleState.CLAVE"
              :key="singleState.NOMBRE"
            >
              {{ singleState.NOMBRE }}
            </option>
          </b-select>
        </b-field>
      </div>

      <h3 class="form-division">Información de transporte</h3>

      <div class="invoice-add-item special-margin">
        <b-field
          class="invoice-add-secondary-item-one"
          label="Vehiculo (Opc)"
          expanded
          label-position="inside"
        >
          <b-select
            placeholder="Ejemplo: Camioneta"
            icon="truck"
            expanded
            v-model="newPorteCard.CLAVE_VEHICULO"
            @input="() => handleVehiculeInput()"
          >
            <option :value="null" disabled>Seleccione un vehiculo</option>
            <option
              v-for="singleVehicule in vehicules"
              :value="singleVehicule._id"
              :key="singleVehicule._id"
            >
              {{ singleVehicule.CVE_VEHI }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-one"
          label-position="inside"
          label="Cantidad total de mercancia (Req)"
          expanded
          :type="{ 'is-danger': newPorteCardErrors.TOTAL_MERCANCIA }"
          :message="{
            'La cantidad total de mercancia no es válida':
              newPorteCardErrors.TOTAL_MERCANCIA,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: 2`"
            icon="numeric"
            required
            v-model="newPorteCard.TOTAL_MERCANCIA"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-one"
          label-position="inside"
          :label="`Peso bruto vehiculo (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.BRUTO_VEHICULO }"
          :message="{
            'El peso bruto del vehiculo no es válido':
              newPorteCardErrors.BRUTO_VEHICULO,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: 100`"
            icon="weight-kilogram"
            required
            v-model="newPorteCard.BRUTO_VEHICULO"
            type="number"
            step="any"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          :label="`Peso bruto total (Req)`"
          :type="{ 'is-danger': newPorteCardErrors.BRUTO_TOTAL }"
          :message="{
            'El peso bruto total no es válido': newPorteCardErrors.BRUTO_TOTAL,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: 100`"
            icon="weight-kilogram"
            required
            v-model="newPorteCard.BRUTO_TOTAL"
            type="number"
            step="any"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three required-input"
          label-position="inside"
          label="Unidad de peso (Req)"
        >
          <b-select
            :placeholder="`Ejemplo: Kilogramo`"
            icon="scale-balance"
            required
            v-model="newPorteCard.UNIDAD_PESO"
            expanded
          >
            <option value="">Seleccione una unidad</option>
            <option
              v-for="singleUnit in weightUnits"
              :value="singleUnit.CLAVE_UNIDAD"
              :key="singleUnit.CLAVE_UNIDAD"
            >
              {{ singleUnit.NOMBRE }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label="Tipo de permisos (Req)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newPorteCardErrors.PERMISO }"
          :message="{
            'El tipo de permiso no es valido': newPorteCardErrors.PERMISO,
          }"
        >
          <b-select
            placeholder="Ejemplo: Transporte privado de carga"
            icon="card-text-outline"
            expanded
            v-model="newPorteCard.PERMISO"
            required
            class="required-input"
          >
            <option value="">Seleccione un tipo de permiso</option>
            <option
              v-for="siglePermission in transportationPermissions"
              :value="siglePermission.CLAVE"
              :key="siglePermission.CLAVE"
            >
              {{ siglePermission.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Número de permisos (Req)"
          expanded
          :type="{ 'is-danger': newPorteCardErrors.NUMERO_PERMISO }"
          :message="{
            'El número de permiso no es válido':
              newPorteCardErrors.NUMERO_PERMISO,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: 000000`"
            icon="card-bulleted"
            required
            v-model="newPorteCard.NUMERO_PERMISO"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label="Configuración autotransporte (Req)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newPorteCardErrors.CONFIGURACION }"
          :message="{
            'La configuración no es válida': newPorteCardErrors.CONFIGURACION,
          }"
        >
          <b-select
            placeholder="Ejemplo: Vehículo ligero de carga (2 llantas en el eje delantero y 2 llantas en el eje trasero)"
            icon="dump-truck"
            expanded
            v-model="newPorteCard.CONFIGURACION"
            required
            class="required-input"
          >
            <option value="">Seleccione una configuración</option>
            <option
              v-for="singleConfigurarion in transportationConfiguration"
              :value="singleConfigurarion.CLAVE"
              :key="singleConfigurarion.CLAVE"
            >
              {{ singleConfigurarion.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Modelo (Req)"
          expanded
          :type="{ 'is-danger': newPorteCardErrors.MODELO }"
          :message="{
            'El modelo no es válido': newPorteCardErrors.MODELO,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: 2020`"
            icon="rv-truck"
            required
            v-model="newPorteCard.MODELO"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Placas (Req)"
          expanded
          :type="{ 'is-danger': newPorteCardErrors.PLACA }"
          :message="{
            'Las placas no son válidas': newPorteCardErrors.PLACA,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: plac892`"
            icon="car-settings"
            required
            v-model="newPorteCard.PLACA"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Nombre de la aseguradora (Req)"
          expanded
          :type="{ 'is-danger': newPorteCardErrors.SEGURO_CIVIL }"
          :message="{
            'Aseguradora no es válida': newPorteCardErrors.SEGURO_CIVIL,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: GNP Seguros`"
            icon="car-door-lock"
            required
            v-model="newPorteCard.SEGURO_CIVIL"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Poliza de seguro (Req)"
          expanded
          :type="{ 'is-danger': newPorteCardErrors.POLIZA_CIVIL }"
          :message="{
            'Aseguradora no es válida': newPorteCardErrors.POLIZA_CIVIL,
          }"
        >
          <b-input
            :placeholder="`Ejemplo: 123456789`"
            icon="card-bulleted-outline"
            required
            v-model="newPorteCard.POLIZA_CIVIL"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Aseguradora de daños a medio ambiente (Opc)"
          expanded
        >
          <b-input
            :placeholder="`Ejemplo: GNP Seguros`"
            icon="car-door-lock"
            v-model="newPorteCard.SEGURO_AMBIENTE"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Poliza de daños a medio ambiente (Opc)"
          expanded
        >
          <b-input
            :placeholder="`Ejemplo: 123456789`"
            icon="card-bulleted-outline"
            v-model="newPorteCard.POLIZA_AMBIENTE"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label="Tipo de remolque 1 (Opc)"
          expanded
          label-position="inside"
        >
          <b-select
            placeholder="Ejemplo: Caja"
            icon="truck-trailer"
            expanded
            v-model="newPorteCard.REMOLQUE_1"
          >
            <option value="">Seleccione una tipo de remolque</option>
            <option
              v-for="singleTrailer in trailerTypes"
              :value="singleTrailer.CLAVE"
              :key="singleTrailer.CLAVE"
            >
              {{ singleTrailer.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Placas del remolque 1 (Opc)"
          expanded
        >
          <b-input
            :placeholder="`Ejemplo: plac892`"
            icon="car-settings"
            v-model="newPorteCard.REMOLQUE_1_PLACA"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label="Tipo de remolque 2 (Opc)"
          expanded
          label-position="inside"
        >
          <b-select
            placeholder="Ejemplo: Caja"
            icon="truck-trailer"
            expanded
            v-model="newPorteCard.REMOLQUE_2"
          >
            <option value="">Seleccione una tipo de remolque</option>
            <option
              v-for="singleTrailer in trailerTypes"
              :value="singleTrailer.CLAVE"
              :key="singleTrailer.CLAVE"
            >
              {{ singleTrailer.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Placas del remolque 2 (Opc)"
          expanded
        >
          <b-input
            :placeholder="`Ejemplo: plac892`"
            icon="car-settings"
            v-model="newPorteCard.REMOLQUE_2_PLACA"
          >
          </b-input>
        </b-field>
      </div>

      <div
        v-for="(singleFigura, index) in newPorteCard.FIGURAS"
        v-bind:key="singleFigura.index"
      >
        <h3 class="form-division">Información de chofer {{ index + 1 }}</h3>
        <div class="invoice-add-item special-margin">
          <b-field
            class="invoice-add-secondary-item-one"
            label="Personal (Opc)"
            expanded
            label-position="inside"
          >
            <b-select
              placeholder="Ejemplo: Chofer 1"
              icon="account-group"
              expanded
              v-model="singleFigura.CLAVE_PERSONAL"
              @input="() => handlePersonalInput(index)"
            >
              <option :value="null">Seleccione el personal</option>
              <option
                v-for="singleEmployee in employees"
                :value="singleEmployee._id"
                :key="singleEmployee._id"
              >
                {{ singleEmployee.CVE_PERS }}
              </option>
            </b-select>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Nombre completo (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: Mario Molina`"
              icon="account"
              required
              v-model="singleFigura.NOMBRE"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three required-input"
            label-position="inside"
            :label="`Tipo de figura (Req)`"
          >
            <b-select
              :placeholder="`Ejemplo: Operador`"
              icon="account-multiple"
              required
              v-model="singleFigura.TIPO_FIGURA"
              expanded
            >
              <option value="">Seleccione un tipo de figura</option>
              <option
                v-for="singleDriverType in driverTypes"
                :value="singleDriverType.CLAVE"
                :key="singleDriverType.DESCRIPCION"
              >
                {{ singleDriverType.DESCRIPCION }}
              </option>
            </b-select>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`RFC (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: VAAM130719H60`"
              icon="credit-card"
              required
              v-model="singleFigura.RFC"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Licencia (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: a234567890`"
              icon="card-account-details"
              required
              v-model="singleFigura.LICENCIA"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Dirección (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: paseo de la flor`"
              icon="file-outline"
              required
              v-model="singleFigura.DIRECCION"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Referencia (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: Casa blanca`"
              icon="file-outline"
              required
              v-model="singleFigura.REFERENCIA"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Número exterior (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: 200`"
              icon="counter"
              required
              v-model="singleFigura.NUMERO_EXTERIOR"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Número interior (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: 21`"
              icon="counter"
              required
              v-model="singleFigura.NUMERO_INTERIOR"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Código postal (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: 00000`"
              icon="directions-fork"
              required
              v-model="singleFigura.CP"
              v-cleave="masks.customCP"
              @input="
                (value) => searchForOriginDirectionInformation(value, 2, index)
              "
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Nombre de la colonia (Req)`"
          >
            <b-autocomplete
              :data="
                getFilteredColonies(
                  singleFigura.zipCodes,
                  singleFigura.NOMBRE_COLONIA
                )
              "
              field="colony"
              open-on-focus
              placeholder="Ejemplo: Del Norte"
              expanded
              icon="home-group"
              v-model="singleFigura.NOMBRE_COLONIA"
              clearable
              @select="
                (option) => (singleFigura.NOMBRE_COLONIA = option.colony)
              "
            >
              <template #empty>No se encontraron colonias</template>
            </b-autocomplete>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Clave de la colonia (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: 0001`"
              icon="sign-real-estate"
              required
              v-model="singleFigura.COLONIA"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Clave de la localidad (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: 01`"
              icon="sign-real-estate"
              required
              v-model="singleFigura.LOCALIDAD"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Nombre del municipio (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: Aguascalientes`"
              icon="sign-real-estate"
              required
              v-model="singleFigura.NOMBRE_MUNICIPIO"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three"
            label-position="inside"
            :label="`Clave del municipio (Req)`"
          >
            <b-input
              :placeholder="`Ejemplo: 001`"
              icon="city-variant-outline"
              required
              v-model="singleFigura.MUNICIPIO"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three required-input"
            label-position="inside"
            :label="`Estado (Req)`"
          >
            <b-select
              :placeholder="`Ejemplo: Aguascalientes`"
              icon="sign-real-estate"
              required
              v-model="singleFigura.ESTADO"
              expanded
            >
              <option value="">Seleccione un estado</option>
              <option
                v-for="singleState in states"
                :value="singleState.CLAVE"
                :key="singleState.NOMBRE"
              >
                {{ singleState.NOMBRE }}
              </option>
            </b-select>
          </b-field>

          <b-button
            v-if="index !== 0 && !objectInformation"
            type="is-danger special-margin"
            @click="deleteIndex(index)"
            >Borrar chofer</b-button
          >
        </div>
      </div>

      <h3 class="form-division">Detalles en carta porte</h3>
      <b-table
        pagination-position="both"
        :data="newPorteCard.DET_PORTE"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="purchaseOrder"
        custom-row-key="RENGLON"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        class="purchase-order-table providers-list-global"
        scrollable
      >
        <template #empty>
          <div class="has-text-centered">No hay detalles</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Acciones" v-if="!objectInformation">
            <b-field class="invoice-option-select">
              <b-select
                placeholder="Opciones"
                v-model="props.row.selectedOption"
                @input="handleOptionChange(props)"
                v-if="props.row.article"
              >
                <option value="">Selecciona una opción</option>
                <option value="deleteItem">Borrar artículo</option>
              </b-select>
            </b-field>
          </b-table-column>

          <b-table-column field="DESCRIPCION" label="Descripción">
            <span v-if="props.row.article">{{ props.row.DESCRIPCION }}</span>

            <div class="invoice-article-div" v-if="!props.row.article">
              <b-button
                icon-right="magnify"
                size="is-small"
                type="is-primary"
                class="search-article"
                @click="openSearchArticleCartaPorte(props)"
              >
              </b-button>
            </div>
          </b-table-column>

          <b-table-column label="Presentación" field="PRESENTACIONES" sortable>
            <b-field class="invoice-option-select">
              <b-select
                placeholder="Ejemplo: PIEZA"
                expanded
                v-model="props.row.PRESENTACION"
                v-if="props.row.article"
              >
                <option value="UB" v-if="!props.row.article.UNIDAD_EMP">
                  Unidad de medida base
                </option>
                <option value="UB" v-if="props.row.article.UNIDAD_EMP">
                  {{
                    capitalizeString(props.row.article.UNIDAD_EMP.DESCRIP_MED)
                  }}
                </option>
                <option
                  v-for="singlePresentation in props.row.article.PRESENTACIONES"
                  :value="singlePresentation._id"
                  :key="singlePresentation._id"
                >
                  {{ singlePresentation.NOMBRE }}
                </option>
              </b-select>
            </b-field>
          </b-table-column>

          <b-table-column
            label="Material peligroso"
            field="MATERIAL_PELIGROSO"
            sortable
          >
            <b-input
              placeholder="Ejemplo: 0,1"
              expanded
              v-model="props.row.MATERIAL_PELIGROSO"
            ></b-input>
          </b-table-column>

          <b-table-column label="Cantidad" field="CANTIDAD">
            <b-input
              placeholder="Ejemplo: 2"
              expanded
              v-model="props.row.CANTIDAD"
              required
            ></b-input>
          </b-table-column>

          <b-table-column label="Peso en KG" field="PESO">
            <b-input
              placeholder="Ejemplo: 10"
              expanded
              v-model="props.row.PESO"
              required
            ></b-input>
          </b-table-column>

          <b-table-column label="Es material peligroso" field="PELIGROSO">
            <b-checkbox
              v-model="props.row.PELIGROSO"
              true-value="S"
              false-value="N"
              :disabled="
                props.row.MATERIAL_PELIGROSO === '1' ||
                props.row.MATERIAL_PELIGROSO === '0'
              "
            >
              Material peligroso
            </b-checkbox>
          </b-table-column>

          <b-table-column
            label="Clave de material peligroso (Solo si es material peligroso)"
            field="CLAVE_MATERIAL_PELIGROSO"
          >
            <b-select
              placeholder="Ejemplo: METANOL"
              expanded
              v-model="props.row.CLAVE_MATERIAL_PELIGROSO"
              :disabled="props.row.MATERIAL_PELIGROSO === '0'"
            >
              <option value="">Seleccione un material peligroso</option>
              <option
                v-for="singleMaterial in dangerousMaterials"
                :value="singleMaterial.CLAVE"
                :key="singleMaterial._id"
              >
                {{ singleMaterial.DESCRIPCION }}
              </option>
            </b-select>
          </b-table-column>

          <b-table-column label="Tipo de embalaje" field="CLAVE_EMBALAJE">
            <b-select
              placeholder="Ejemplo: Cajas de carton"
              expanded
              v-model="props.row.CLAVE_EMBALAJE"
              :disabled="props.row.MATERIAL_PELIGROSO === '0'"
            >
              <option value="">Seleccione un tipo de embalaje</option>
              <option
                v-for="singlePackage in packaging"
                :value="singlePackage.CLAVE"
                :key="singlePackage._id"
              >
                {{ singlePackage.DESCRIPCION }}
              </option>
            </b-select>
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="savePorteCard()"
        v-if="!objectInformation"
        >Agregar carta porte</b-button
      >

      <b-button
        type="is-danger"
        @click="closeModal()"
        :class="{
          'cancel-button': !objectInformation,
        }"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import Cleave from "cleave.js";
import {
  checkValidationErrors,
  validateFields,
  capitalizeFirstLetter,
} from "@/utils/fns";
import Masks from "../../../data/cleave-masks";
import States from "../../../data/states";
import DriverTypes from "../../../data/driver-type";
import { EventBus } from "@/event-bus";
import AddSelectArticle from "./AddSelectArticle";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "AddPorteCardNoSale",
  props: ["objectInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      // Table values
      newPorteCard: {
        zipCodes: [],
        DISTANCIA_TOTAL: "",
        FECHA_SALIDA: new Date(),
        ID_UBICACION: "",
        DIRECCION: "",
        NUMERO_EXTERIOR: "",
        NUMERO_INTERIOR: "",
        REFERENCIA: "",
        CP: "",
        NOMBRE_COLONIA: "",
        COLONIA: "",
        LOCALIDAD: "",
        NOMBRE_MUNICIPIO: "",
        MUNICIPIO: "",
        ESTADO: "",
        RFC: "",
        DESTINOS: [
          {
            zipCodes: [],
            FECHA_SALIDA: new Date(),
            DISTANCIA: "",
            ID_UBICACION: "",
            DIRECCION: "",
            NUMERO_EXTERIOR: "",
            NUMERO_INTERIOR: "",
            REFERENCIA: "",
            CP: "",
            NOMBRE_COLONIA: "",
            COLONIA: "",
            LOCALIDAD: "",
            NOMBRE_MUNICIPIO: "",
            MUNICIPIO: "",
            ESTADO: "",
          },
        ],
        CLAVE_VEHICULO: null,
        BRUTO_TOTAL: "",
        UNIDAD_PESO: "KGM",
        TOTAL_MERCANCIA: "",
        PERMISO: "TPXX00",
        NUMERO_PERMISO: "Permiso no contemplado en el catálogo",
        CONFIGURACION: "VL",
        MODELO: "",
        PLACA: "",
        SEGURO_CIVIL: "",
        POLIZA_CIVIL: "",
        SEGURO_AMBIENTE: "",
        POLIZA_AMBIENTE: "",
        REMOLQUE_1: "",
        REMOLQUE_1_PLACA: "",
        REMOLQUE_2: "",
        REMOLQUE_2_PLACA: "",
        BRUTO_VEHICULO: 0,
        FIGURAS: [
          {
            zipCodes: [],
            NOMBRE: "",
            CLAVE_PERSONAL: null,
            TIPO_FIGURA: "01",
            RFC: "",
            LICENCIA: "",
            DIRECCION: "",
            NUMERO_EXTERIOR: "",
            NUMERO_INTERIOR: "",
            REFERENCIA: "",
            CP: "",
            NOMBRE_COLONIA: "",
            COLONIA: "",
            LOCALIDAD: "",
            NOMBRE_MUNICIPIO: "",
            MUNICIPIO: "",
            ESTADO: "",
          },
        ],
        DET_PORTE: [
          {
            article: null,
            CLAVE_ART: null,
            PRESENTACION: "UB",
            selectedOption: "",
            DESCRIPCION: "",
            CANTIDAD: "",
            PESO: "",
            MATERIAL_PELIGROSO: "0,1",
            PELIGROSO: "N",
            CLAVE_MATERIAL_PELIGROSO: "",
            CLAVE_EMBALAJE: "",
            ID_ORIGEN: "",
            ID_DESTINO: "",
          },
        ],
      },
      newPorteCardErrors: {
        DISTANCIA_TOTAL: false,
        ID_UBICACION: false,
        DIRECCION: false,
        NUMERO_EXTERIOR: false,
        CP: false,
        NOMBRE_COLONIA: false,
        COLONIA: false,
        LOCALIDAD: false,
        REFERENCIA: false,
        MUNICIPIO: false,
        ESTADO: false,
        BRUTO_TOTAL: false,
        UNIDAD_PESO: false,
        TOTAL_MERCANCIA: false,
        PERMISO: false,
        NUMERO_PERMISO: false,
        BRUTO_VEHICULO: false,
        CONFIGURACION: false,
        MODELO: false,
        PLACA: false,
        SEGURO_CIVIL: false,
        POLIZA_CIVIL: false,
        RFC: false,
      },
      moment: moment,
      perPage: 25,
      checkedWarehouses: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      masks: Masks,
      states: States,
      driverTypes: DriverTypes,
      selectedClient: "",
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    EventBus.$on("addSelectArticleSelected", (selection) => {
      this.newPorteCard.DET_PORTE[selection.index].article = selection.article;
      this.newPorteCard.DET_PORTE[selection.index].CLAVE_ART =
        selection.article._id;
      this.newPorteCard.DET_PORTE[selection.index].DESCRIPCION =
        selection.article.DESCRIPCION;
      this.newPorteCard.DET_PORTE[selection.index].CANTIDAD = 1;
      this.newPorteCard.DET_PORTE[selection.index].PESO =
        selection.article.CANT_PESO;
      this.addRow();
    });

    // Fill the brand information in form fields
    if (this.objectInformation) {
      this.newPorteCard = this.objectInformation;
      this.newPorteCard.zipCodes = [];
      this.newPorteCard.FECHA_SALIDA = new Date(
        this.objectInformation.FECHA_SALIDA
      );

      for (const [
        singleDestinoIndex,
        singleDestino,
      ] of this.newPorteCard.DESTINOS.entries()) {
        this.newPorteCard.DESTINOS[singleDestinoIndex].zipCodes = [];
        this.newPorteCard.DESTINOS[singleDestinoIndex].FECHA_SALIDA = new Date(
          this.objectInformation.DESTINOS[singleDestinoIndex].FECHA_SALIDA
        );
      }

      for (const [
        singleFiguraIndex,
        singleFigura,
      ] of this.newPorteCard.FIGURAS.entries()) {
        this.newPorteCard.FIGURAS[singleFiguraIndex].zipCodes = [];
      }
    } else {
      this.newPorteCard.DIRECCION = this.companyInformation.DOMICIL;
      this.newPorteCard.NUMERO_EXTERIOR = this.companyInformation.NUM_EXTE;
      this.newPorteCard.NUMERO_INTERIOR = this.companyInformation.NUM_INTER;
      this.newPorteCard.REFERENCIA = this.companyInformation.REFERENCIA;
      this.newPorteCard.CP = this.companyInformation.CP;
      this.newPorteCard.NOMBRE_COLONIA = this.companyInformation.NOMBRE_COLONIA;
      this.newPorteCard.COLONIA = this.companyInformation.COLONIA;
      this.newPorteCard.LOCALIDAD = this.companyInformation.LOCALIDAD;
      this.newPorteCard.NOMBRE_MUNICIPIO =
        this.companyInformation.NOMBRE_MUNICIPIO;
      this.newPorteCard.MUNICIPIO = this.companyInformation.MUNICIPIO;
      this.newPorteCard.ESTADO = this.companyInformation.ESTADO;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    closeParentModal() {
      EventBus.$emit("closeParetModal");
      this.closeModal();
    },
    capitalizeString(string) {
      return capitalizeFirstLetter(string);
    },
    deleteRow(item) {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPorteCard.DET_PORTE.entries()) {
        if (singleItemIndex === item.index) {
          this.newPorteCard.DET_PORTE.splice(singleItemIndex, 1);
        }
      }
    },
    handleOptionChange(item) {
      if (item.row.selectedOption === "deleteItem") {
        this.deleteRow(item);
      }

      setTimeout(() => {
        item.selectedOption = "";
      }, 500);
    },
    async savePorteCard() {
      try {
        this.newPorteCardErrors = validateFields(
          this.newPorteCard,
          this.newPorteCardErrors
        );
        const porteItemsValidation = this.validatePorteItems();
        if (
          !checkValidationErrors(this.newPorteCardErrors) ||
          !porteItemsValidation
        ) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al agregar la carta porte en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        let response = await this.$store.dispatch("SAVEPORTECARDNOSALE", {
          porteCard: this.newPorteCard,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: `Se guardo exitosamente la carta porte`,
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
          this.closeParentModal();
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la carta porte:  ${
              response ? JSON.stringify(response) : "Error en el servidor"
            }`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al guardar la carta portef: ${
            error ? JSON.stringify(error) : "Error en el servidor"
          }`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    getClientInformation(clientId) {
      for (const singleClient of this.clients) {
        if (singleClient._id === clientId) {
          return singleClient;
        }
      }
    },
    addRow() {
      const newRow = {
        article: null,
        CLAVE_ART: null,
        PRESENTACION: "UB",
        selectedOption: "",
        DESCRIPCION: "",
        CANTIDAD: "",
        PESO: "",
        MATERIAL_PELIGROSO: "0,1",
        PELIGROSO: "N",
        CLAVE_MATERIAL_PELIGROSO: "",
        CLAVE_EMBALAJE: "",
        ID_ORIGEN: "",
        ID_DESTINO: "",
      };
      this.newPorteCard.DET_PORTE.push(newRow);
    },
    openSearchArticleCartaPorte(articleRow) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddSelectArticle,
        props: {
          articleRow,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    getVehiculeInformation(vehiculeId) {
      for (const singleVehicule of this.vehicules) {
        if (singleVehicule._id === vehiculeId) {
          return singleVehicule;
        }
      }
    },
    getEmployeeInformation(employeeId) {
      for (const singleEmployee of this.employees) {
        if (singleEmployee._id === employeeId) {
          return singleEmployee;
        }
      }
    },
    addDriver() {
      this.newPorteCard.FIGURAS.push({
        CLAVE_PERSONAL: null,
        NOMBRE: "",
        TIPO_FIGURA: "",
        RFC: "",
        LICENCIA: "",
        DIRECCION: "",
        NUMERO_EXTERIOR: "",
        NUMERO_INTERIOR: "",
        REFERENCIA: "",
        CP: "",
        NOMBRE_COLONIA: "",
        COLONIA: "",
        LOCALIDAD: "",
        NOMBRE_MUNICIPIO: "",
        MUNICIPIO: "",
        ESTADO: "",
        zipCodes: [],
      });
    },
    deleteIndex(index) {
      this.newPorteCard.FIGURAS.splice(index, 1);
    },
    handleVehiculeInput() {
      const vehiculeInformation = this.getVehiculeInformation(
        this.newPorteCard.CLAVE_VEHICULO
      );
      this.newPorteCard.PLACA = vehiculeInformation.PLACAS;
      this.newPorteCard.MODELO = vehiculeInformation.MODELO;
      if (vehiculeInformation.SEGURO_CIVIL) {
        this.newPorteCard.SEGURO_CIVIL = vehiculeInformation.SEGURO_CIVIL;
      }
      if (vehiculeInformation.POLIZA_CIVIL) {
        this.newPorteCard.POLIZA_CIVIL = vehiculeInformation.POLIZA_CIVIL;
      }

      if (vehiculeInformation.SEGURO_AMBIENTE) {
        this.newPorteCard.SEGURO_AMBIENTE = vehiculeInformation.SEGURO_AMBIENTE;
      }
      if (vehiculeInformation.POLIZA_AMBIENTE) {
        this.newPorteCard.POLIZA_AMBIENTE;
      }
      if (vehiculeInformation.BRUTO) {
        this.newPorteCard.BRUTO_VEHICULO = vehiculeInformation.BRUTO;
      }
    },
    handlePersonalInput(index) {
      const employeeInformation = this.getEmployeeInformation(
        this.newPorteCard.FIGURAS[index].CLAVE_PERSONAL
      );
      this.newPorteCard.FIGURAS[
        index
      ].NOMBRE = `${employeeInformation.NOMBRE} ${employeeInformation.PATERNO} ${employeeInformation.MATERNO}`;
      this.newPorteCard.FIGURAS[index].RFC = employeeInformation.RFC;
      this.newPorteCard.FIGURAS[index].DIRECCION = employeeInformation.DOMICIL;
      this.newPorteCard.FIGURAS[index].NUMERO_EXTERIOR =
        employeeInformation.NUM_EXTER;
      this.newPorteCard.FIGURAS[index].NUMERO_INTERIOR =
        employeeInformation.NUM_INTER;
      this.newPorteCard.FIGURAS[index].REFERENCIA =
        employeeInformation.REFERENCIA;
      this.newPorteCard.FIGURAS[index].CP = employeeInformation.CP;
      this.newPorteCard.FIGURAS[index].NOMBRE_COLONIA =
        employeeInformation.NOMBRE_COLONIA;
      this.newPorteCard.FIGURAS[index].COLONIA = employeeInformation.COLONIA;
      this.newPorteCard.FIGURAS[index].LOCALIDAD =
        employeeInformation.LOCALIDAD;
      this.newPorteCard.FIGURAS[index].NOMBRE_MUNICIPIO =
        employeeInformation.NOMBRE_MUNICIPIO;
      this.newPorteCard.FIGURAS[index].MUNICIPIO =
        employeeInformation.MUNICIPIO;
      this.newPorteCard.FIGURAS[index].ESTADO = employeeInformation.ESTADO;
    },
    validatePorteItems() {
      const validArticles = [];

      let valid = true;
      // Set ID_ORIGEN and ID_DESTINO in porte details
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPorteCard.DET_PORTE.entries()) {
        this.newPorteCard.DET_PORTE[singleItemIndex].ID_ORIGEN =
          this.newPorteCard.ID_UBICACION;
        this.newPorteCard.DET_PORTE[singleItemIndex].ID_DESTINO =
          this.newPorteCard.DESTINOS[0].ID_UBICACION;
      }

      // Validate DESTINOS
      for (const singleDestiny of this.newPorteCard.DESTINOS) {
        if (
          !singleDestiny.DISTANCIA ||
          !singleDestiny.FECHA_SALIDA ||
          !singleDestiny.ID_UBICACION ||
          !singleDestiny.DIRECCION ||
          !singleDestiny.CP ||
          !singleDestiny.NOMBRE_COLONIA ||
          !singleDestiny.COLONIA ||
          !singleDestiny.LOCALIDAD ||
          !singleDestiny.NOMBRE_MUNICIPIO ||
          !singleDestiny.MUNICIPIO ||
          !singleDestiny.ESTADO
        ) {
          valid = false;
        }
      }

      for (const singleArticle of this.newPorteCard.DET_PORTE) {
        if (singleArticle.CLAVE_ART) {
          validArticles.push(singleArticle);
        }
      }

      this.newPorteCard.DET_PORTE = validArticles;

      return valid;
    },
    async searchForOriginDirectionInformation(zipCode, type, index) {
      if (zipCode.length === 5) {
        let response = await this.$store.dispatch(
          "SEARCHDIRECTIONINFORMATION",
          { zipCode }
        );
        if (response.length > 0) {
          if (type === 0) {
            this.newPorteCard.zipCodes = response;
            this.newPorteCard.ESTADO = response[0].state_code;
            this.newPorteCard.NOMBRE_MUNICIPIO = response[0].municipality;
            this.newPorteCard.MUNICIPIO = response[0].municipio_code;
            this.newPorteCard.LOCALIDAD = response[0].localidad_code;
            this.newPorteCard.NOMBRE_COLONIA = response[0].colony;
            this.newPorteCard.COLONIA = response[0].colony_code;
          } else if (type === 1) {
            this.newPorteCard.DESTINOS[index].zipCodes = response;
            this.newPorteCard.DESTINOS[index].ESTADO = response[0].state_code;
            this.newPorteCard.DESTINOS[index].NOMBRE_MUNICIPIO =
              response[0].municipality;
            this.newPorteCard.DESTINOS[index].MUNICIPIO =
              response[0].municipio_code;
            this.newPorteCard.DESTINOS[index].LOCALIDAD =
              response[0].localidad_code;
            this.newPorteCard.DESTINOS[index].NOMBRE_COLONIA =
              response[0].colony;
            this.newPorteCard.DESTINOS[index].COLONIA = response[0].colony_code;
          } else if (type === 2) {
            this.newPorteCard.FIGURAS[index].zipCodes = response;
            this.newPorteCard.FIGURAS[index].ESTADO = response[0].state_code;
            this.newPorteCard.FIGURAS[index].NOMBRE_MUNICIPIO =
              response[0].municipality;
            this.newPorteCard.FIGURAS[index].MUNICIPIO =
              response[0].municipio_code;
            this.newPorteCard.FIGURAS[index].LOCALIDAD =
              response[0].localidad_code;
            this.newPorteCard.FIGURAS[index].NOMBRE_COLONIA =
              response[0].colony;
            this.newPorteCard.FIGURAS[index].COLONIA = response[0].colony_code;
          }
        }
      }
    },
    getFilteredColonies(coloniesArray, string) {
      return coloniesArray.filter((singleColony) => {
        return (
          singleColony.colony
            .toString()
            .toLowerCase()
            .indexOf(string.toLowerCase()) >= 0
        );
      });
    },
  },
  computed: {
    cfdiUses() {
      return this.$store.getters.SATCFDIUSES.map((singleUse) => {
        const useWithProps = { ...singleUse };
        if (useWithProps.TIPO_P === "A") {
          useWithProps.typeFormatted = "Ambos";
        } else if (useWithProps.TIPO_P === "F") {
          useWithProps.typeFormatted = "Persona física";
        } else if (useWithProps.TIPO_P === "M") {
          useWithProps.typeFormatted = "Persona moral";
        }
        return useWithProps;
      });
    },
    regimenes() {
      return this.$store.getters.SATREGIMENES.map((singleRegimen) => {
        const regimenWithProps = { ...singleRegimen };
        if (regimenWithProps.TIPO_R === "A") {
          regimenWithProps.typeFormatted = "Ambos";
        } else if (regimenWithProps.TIPO_R === "F") {
          regimenWithProps.typeFormatted = "Persona física";
        } else if (regimenWithProps.TIPO_R === "M") {
          regimenWithProps.typeFormatted = "Persona moral";
        }
        return regimenWithProps;
      });
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    weightUnits() {
      const weightUnitsWithProps = [...this.$store.getters.WEIGHTUNITS];
      return weightUnitsWithProps.sort((a, b) => {
        var textA = a.NOMBRE.toUpperCase();
        var textB = b.NOMBRE.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    transportationPermissions() {
      const transportationPermissionsWithProps = [
        ...this.$store.getters.TRANSPORTATIONPERMISSIONS,
      ];
      return transportationPermissionsWithProps.sort((a, b) => {
        var textA = a.DESCRIPCION.toUpperCase();
        var textB = b.DESCRIPCION.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    transportationConfiguration() {
      const transportationConfigurationsWithProps = [
        ...this.$store.getters.TRANSPORTATIONCONFIGURATION,
      ];
      return transportationConfigurationsWithProps.sort((a, b) => {
        var textA = a.DESCRIPCION.toUpperCase();
        var textB = b.DESCRIPCION.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    trailerTypes() {
      const trailerTypesWithProps = [...this.$store.getters.TRAILERTYPES];
      return trailerTypesWithProps.sort((a, b) => {
        var textA = a.DESCRIPCION.toUpperCase();
        var textB = b.DESCRIPCION.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    vehicules() {
      const vehiculesWithProps = [...this.$store.getters.VEHICULES];
      return vehiculesWithProps;
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      });
    },
    dangerousMaterials() {
      const dangerousMaterialsWithProps = [
        ...this.$store.getters.DANGEROUSMATERIALS,
      ];
      return dangerousMaterialsWithProps.sort((a, b) => {
        var textA = a.DESCRIPCION.toUpperCase();
        var textB = b.DESCRIPCION.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    packaging() {
      const packagingWithProps = [...this.$store.getters.PACKAGINGS];
      return packagingWithProps.sort((a, b) => {
        var textA = a.DESCRIPCION.toUpperCase();
        var textB = b.DESCRIPCION.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const singleClientWithProps = { ...singleClient };
        return singleClientWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
      });
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
