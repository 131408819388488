const driverTypes = [
  {
    CLAVE: "01",
    DESCRIPCION: "Operador",
  },
  {
    CLAVE: "02",
    DESCRIPCION: "Propietario",
  },
  {
    CLAVE: "03",
    DESCRIPCION: "Arrendador",
  },
  {
    CLAVE: "04",
    DESCRIPCION: "Notificado",
  },
];

export default driverTypes;
